.c-referral {
  background: #fff;

  &__hero {
    background: linear-gradient(to left, #fff7e7, #fff7e7, #fff9ef);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 320px;
    margin-bottom: 3rem;
    padding: 11.6rem 0 5rem;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    text-align: left;

    @media screen and (max-width: 760px) {
      padding-bottom: 3.2rem;
    }

    h3 {
      font-size: 5.2rem;
      margin: 20px 0 8px;
      max-width: 500px;
      line-height: 58px;
      font-weight: 700;
      position: relative;

      @media screen and (max-width: 760px) {
        font-size: 4rem;
        line-height: 46px;
      }

      span {
        background: linear-gradient(270deg,
            #9f2fff,
            #5c34de,
            #6baeec);
        animation: textAminate 8s linear infinite;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 800% 800%;
      }

      svg {
        @media screen and (max-width: 760px) {
          left: 140px !important;
          bottom: -21px !important;
        }
      }
    }

    p {
      font-size: 2rem;
      margin: 12px 0 8px;
      max-width: 586px;
      line-height: 30px;
      font-weight: 400;
      position: relative;

      span {
        font-weight: 700;
      }
    }

    h5 {
      font-size: 13px;
      line-height: 12px;
      font-weight: 400;
      font-family: var(--font-primary);
      display: initial;
      margin: 0 0 1.2rem;
      background-image: linear-gradient(90deg, rgba(102, 112, 245, 0.16) 0%, rgba(253, 226, 214, 0.75) 70.02%);
      padding-block: 0.6rem;
      padding-inline: 1.6rem;
      border-radius: 36px;
    }

    h6 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: capitalize;
      margin: 16px 0 8px;
    }
  }

  &__glitz {
    transition: all 0.3s ease-in-out;
    // transform-origin: center 66%;

    &-two {
      animation: starGlitter 5s ease-in-out .2s infinite forwards;
      transform-origin: 82% 44%;
    }

    &-three {
      animation: scaleUp 3s cubic-bezier(0.94, -0.52, 0.36, 0.99) 0.2s infinite forwards;
      transform-origin: 35% 25%;
    }
  }

  &__giftbox {
    position: absolute;

    right: -138px;
    top: 64px;

    @media screen and (max-width: 760px) {
      display: none;
    }

    @media only screen and (min-width: 1400px) {
      right: -161px;
      top: 37px;
    }


  }

  &__coinsvg {
    position: absolute;
    left: -41px;

    @media screen and (max-width: 760px) {
      display: none;
    }

    @media only screen and (min-width: 1400px) {
      left: 0;
    }

  }

  &__sunburst {
    z-index: -1;
    position: absolute;
    object-fit: cover;
    object-position: center -100px;

    @media screen and (max-width: 760px) {
      object-position: unset;
      aspect-ratio: 1;
    }

    @media only screen and (min-width: 1400px) {
      object-position: center -200px;
    }
  }

  .l-col-7,
  .l-col-9 {
    @media screen and (max-width: 760px) {
      flex: 0 0 90%;
    }
  }
}


@keyframes textAminate {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}


@keyframes starGlitter {

  from,
  to {
    transform: scale(0.3) rotate(0deg);
  }

  50% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes scaleUp {

  from,
  to {
    transform: scale(0.3);
  }

  50% {
    transform: scale(1);
  }
}