.c-home {
  position: relative;
  background-color: #fffdf9;
  // background-image: url(./images/Bkgrid.svg);
  min-height: 100vh;
  // overflow: hidden;
  // padding-bottom: 2rem;
  isolation: isolate;

  @media screen and (max-width: 760px) {
    overflow: hidden;
  }
}

.c-grid {
  background-size: 86px 86px;
  background-image: linear-gradient(to right, #FEE1A7 1px, transparent 1px), linear-gradient(to bottom, #FEE1A7 1px, transparent 1px);
  position: absolute;
  inset: 0;
  z-index: -1;
}

.c-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, #fffdf9 20%, rgb(255 253 249 / 20%));
  z-index: -1;
}

.c-manifest {
  padding: 12rem 0 3.2rem;
  position: relative;
  isolation: isolate;

  @media screen and (max-width: 760px) {
    padding: 12rem 0.2rem 6rem;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding-top: 13rem;
  }

  @media only screen and (min-width: 1400px) {
    padding: 12.4rem 0 2.4rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    @media screen and (max-width: 760px) {
      margin-bottom: 2.8rem;
    }

    // @media screen and (max-width: 760px) {
    //   margin-left: 1rem;
    // }

    // &::after {
    //   content: url(./images/swup-alt.svg);
    //   display: block;
    //   position: absolute;
    //   top: 62px;
    //   left: -2px;
    // }
    a.referral {
      display: flex;
      justify-content: space-between;
      gap: 0.8rem;
      font-size: 16px;
      font-weight: 400;
      margin: 0 0 1.2rem;
      background-image: linear-gradient(90deg, rgba(102, 112, 245, 0.16) 0%, rgba(253, 226, 214, 0.75) 70.02%);
      padding-block: 0.4rem;
      padding-inline: 1.6rem 0.4rem;
      border-radius: 36px;
      text-decoration: none;

      span {
        font-family: system-ui,
          "Segoe UI",
          Roboto,
          Helvetica,
          Arial,
          sans-serif,
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol";
        background: rgb(248 218 207);
        padding: 0px 8px;
        transition: inherit;
        -webkit-box-align: center;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    h2 {
      --font-size: 86px;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 0.96);
      letter-spacing: -0.02rem;
      color: rgba(var(--theme-black-rgb), 1);
      width: 100%;
      max-width: 860px;
      isolation: isolate;
      z-index: 10;
      position: relative;
      margin-block: 0.2rem 1rem;
      overflow: hidden;
      min-height: 186px;
      // white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 760px) {
        font-size: 60px;
        line-height: 64px;
        min-height: 203px;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        font-size: 52px;
      }

      &>span {
        position: absolute;
        z-index: 200;

        @media screen and (max-width: 760px) {
          display: initial;
          flex-direction: column;
        }

        @media only screen and (min-width: 1400px) {
          margin-left: 0.4rem;
        }
      }

      i {
        display: inline-flex;
        height: 76px;
        overflow: hidden;
        position: relative;
        min-width: 340px;
        font-style: normal;

        @media screen and (max-width: 760px) {
          justify-content: center;
          height: 60px;

        }

        span {
          position: absolute;
          top: -82px;
          white-space: nowrap;
          transition: all 0.3s ease-in-out;
          display: flex;
          align-items: center;
          font-size: 56px;

          @media screen and (max-width: 760px) {
            font-size: 32px;
            top: -74px;
          }

          &.animated-in {
            // transform: translateY(-54px);
            animation-name: SlideIn;
            animation-duration: .6s;
            animation-fill-mode: forwards;
            // animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            // animation-iteration-count: infinite;
          }

          img {
            max-width: 50px;
            margin: -5px 8px 0 2px;
          }
        }
      }

      &:before {
        // content: url(./images/wink.svg);
        display: inline-block;
        position: absolute;
        left: 20%;
        top: 86px;
        z-index: -1;
        isolation: isolate;
        transform: rotate(330deg) scale(0.8);

        // @media screen and (max-width: 760px) {
        //   content: url(./images/stroke-mobile.svg);
        //   left: 130px;
        //   top: 16px;
        //   width: 30px;
        //   transform: rotate(3deg) scale(0.8);
        // }
      }

    }

    p {
      --font-size: 26px;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.5);
      margin: 1.2rem 0 0.8rem;
      font-family: var(--font-text);
      max-width: 540px;
      font-weight: 400;
      position: relative;

      @media screen and (max-width: 760px) {
        font-size: 21px;
        line-height: 32px;
      }


      &:after {
        // content: url(./images/sparkle.svg);
        display: inline-block;
        position: absolute;
        left: 85%;
        top: 10%;
        z-index: -1;
        isolation: isolate;
        transform: rotate(27deg) scale(0.4);

      }
    }

  }

  &__form-wrap {
    form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;

      @media screen and (max-width: 760px) {
        flex-direction: column;
      }

      input {
        background: transparent;
        min-width: 320px;
        min-height: 56px;
        border: 2px solid var(--theme-black);
        border-radius: 32px;
        padding: 0 2rem;
        font-size: 1.6rem;
        color: var(--theme-black);
        font-weight: 400;

        ::placeholder {
          color: #ffffff59;
          font-weight: 400;
        }

        &:focus {
          border: 2px solid #483f53;
          outline: none;
          box-shadow: none;
        }
      }

      .validation-error {
        background: var(--theme-accent);
        border-radius: 36px;
        font-size: 16px;
        color: #092441;
        margin-top: 1.2rem;
        min-height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
      }

    }
  }

  &__button {
    background: var(--theme-primary);
    border-radius: 32px;
    color: var(--white);
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1.2rem 2.8rem;
    transition: all .32s ease;
    border: 1px solid var(--theme-primary);
    transition: all .2s ease-in-out;
    min-height: 56px;
    cursor: pointer;
    box-shadow: 0 2px 8px -2px #0000004d,
      0 1px 1px #0000002e,
      inset 0 1px #fff3,
      inset 0 -1px #0003;

    &:hover {
      background: darken(#6D23F9, 10%);
      border-color: darken(#6D23F9, 10%);
      transform: translateY(-3px);
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  &__buttons {
    margin: 8px 0 12px;
    display: flex;
    gap: 1.6rem;

    a {
      transition: all .2s ease-in-out;
      position: relative;

      &:hover {
        transform: translateY(-3px);
      }

      &::before {
        background-color: rgb(201 173 255);
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 5px;
        z-index: -1;
        border-radius: 42px;
      }
    }
  }

  &__illustration {
    position: absolute;

    &--currency {
      top: 27%;
      right: 22%;
    }
  }


}

.c-media {
  position: relative;
  z-index: 50;
  margin-block-start: 14rem;

  @media screen and (max-width: 760px) {
    margin-block-start: 6rem;
  }


  &__images-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 3.6rem;
    position: relative;

  }

  &__image {
    position: absolute;

    &--1 {
      width: 23%;
      bottom: 60px;
      z-index: 100;
      position: relative;

      @media screen and (max-width: 760px) {
        width: 66%;
        bottom: initial;
      }

      @media only screen and (min-width: 1400px) {
        width: 25%;
        // bottom: -50px;
      }
    }

    &--2 {
      width: 18%;
      left: 56.6%;
      top: -51px;

      @media screen and (max-width: 760px) {
        width: 62%;
        left: 54.6%;
        top: -47px;
      }

      @media only screen and (min-width: 1400px) {
        width: 24%;
        left: 55%;
        top: -80px;
      }

    }

    &--3 {
      width: 26%;
      left: 55.3%;
      top: 148px;


      @media screen and (max-width: 760px) {
        width: 65%;
        left: 47.3%;
        top: 203px;
      }

      @media only screen and (min-width: 1400px) {
        width: 28%;
        left: 56%;
        top: 200px;
      }
    }

    &--4 {
      width: 30%;
      left: 20%;
      top: 58px;

      @media screen and (max-width: 760px) {
        width: 78%;
        left: -23%;
        top: 89px;
      }

      @media only screen and (min-width: 1400px) {
        width: 32%;
        left: 16%;
        top: 50px;
      }
    }

    &--5 {
      width: 19%;
      left: 297px;
      top: 20px;
      z-index: 10;

      @media screen and (max-width: 760px) {
        display: none;
      }

      @media only screen and (min-width: 1400px) {
        width: 18%;
        left: 424px;
      }
    }

    &--6 {
      width: 19%;
      right: 303px;
      bottom: 367px;
      z-index: 10;

      // backface-visibility: hidden;
      // -webkit-backface-visibility: hidden;
      // outline: 1px solid transparent;
      // transform-style: preserve-3d;
      // transform: rotateZ(358deg);
      // will-change: transform;
      @media screen and (max-width: 760px) {
        display: none;
      }

      @media only screen and (min-width: 1400px) {
        width: 18%;
        right: 428px;
        bottom: 370px;
        z-index: 10;
      }
    }

    &--7 {
      width: 19%;
      right: 300px;
      top: -84px;

      @media screen and (max-width: 760px) {
        display: none;
      }

      @media only screen and (min-width: 1400px) {
        width: 18%;
        right: 420px;
        top: -80px;
      }
    }

  }
}

.c-wordmark {
  // --bg-color: var(--theme-black);
  --bg-color: var(--theme-primary);
  background: var(--bg-color);
  --marquee_space: 2rem;
  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--marquee_space);
  width: 100%;
  border-radius: 52px 52px 0 0;
  font-family: var(--font-heading);
  font-feature-settings: "salt"on;

  @media screen and (max-width: 760px) {
    border-radius: 32px 32px 0 0;
  }
}

.c__marquee {
  --marquee_duration: 60s;
  --marquee_gap: var(--marquee_space);

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--marquee_gap);
  padding-block: 3rem 8rem;
  // transform: skewY(-3deg);
}


.c__marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--marquee_gap);
  min-width: 100%;
  animation: scroll var(--marquee_duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .c__marquee__group {
    animation-play-state: paused;
  }
}

.c__marquee__group img {
  max-width: clamp(10rem, 1rem + 28vmin, 20rem);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
}

.c__marquee__group p {
  color: #ffff;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.c__marquee--reverse .c__marquee__group {
  animation-direction: reverse;
  animation-delay: calc(var(--marquee_duration) / -2);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--marquee_gap)));
  }
}


.c-features {
  --bg-color: #FFFBF2;
  background: var(--bg-color);
  width: 100%;
  border-radius: 52px 52px 0 0;
  min-height: 100vh;
  margin-block-start: -52px;
  display: block;
  z-index: 100;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  // padding-block: 3.6rem 8rem;

  @media screen and (max-width: 760px) {
    border-radius: 32px 32px 0 0;
  }

  &__background {
    position: absolute;

    &--top {
      inset-block-start: -350px;
      inset-inline-end: -60px;
      width: 50%;
    }

    &--bottom {
      inset-block-end: -250px;
      inset-inline-start: -12px;
      width: 30%;
    }
  }

  &__intro {
    padding-block: 8.4rem 7.2rem;
    position: relative;
    z-index: 100;

    @media screen and (max-width: 760px) {
      padding-block: 4.4rem 3.2rem;
      padding-inline: 0.4rem;
    }

    h4 {
      --color: #888682;
      --font-size: 5.6rem;
      font-weight: 500;
      font-size: var(--font-size);
      color: var(--color);
      line-height: calc(var(--font-size) * 1.2);
      font-feature-settings: "salt"on;
      margin-bottom: 1.6rem;

      @media screen and (max-width: 760px) {
        --font-size: 3.6rem;
        line-height: calc(var(--font-size) * 1.3);
      }

      @media (min-width: 1400px) {
        --font-size: 5.6rem;
      }

      span.c-features__virtual {
        color: var(--theme-primary);
      }

      &:nth-child(2) {
        --color: var(--theme-black);

        span {
          span {
            color: var(--theme-primary);
            // -webkit-text-stroke: 1px #ffe4a8;
            // text-shadow: 3px 3px 0 #ffe4a8,
            //   -1px -1px 0 #ffe4a8,
            //   1px -1px 0 #ffe4a8,
            //   -1px 1px 0 #ffe4a8,
            //   1px 1px 0 #ffe4a8;
          }
        }
      }
    }

  }

  &__card {
    --card-bg: #fff;
    background: var(--card-bg);
    border-radius: 44px;
    padding: 4.3rem 4rem 0;
    margin-bottom: 5.2rem;
    position: relative;
    min-height: 700px;
    overflow: hidden;
    isolation: isolate;


    @media screen and (max-width: 760px) {
      min-height: 600px;
    }

    // box-shadow: 0 2px 6px 0 rgb(244 239 226);

    &--left {
      margin-inline-end: 2rem;
    }

    img {
      position: absolute;

      &.funding-img {
        width: 80%;
        right: 0;
        bottom: -6px;

        @media screen and (max-width: 760px) {
          width: 100%;
        }
      }

      &.limit-img {
        width: 100%;
        right: -4px;
        bottom: 1px;
      }

      &.multiple-img {
        width: 92%;
        right: 37px;
        bottom: 0px;
      }

      &.variant-img {
        width: 75%;
        right: 82px;
        bottom: 50px;

        @media screen and (max-width: 760px) {
          width: 100%;
          right: 3px;
          bottom: 33px;
        }
      }

      &.coin-img {
        width: 33%;
        top: 218px;
        right: 60px;

        @media screen and (max-width: 760px) {
          top: 248px;
          right: 15px;
        }
      }

      &.coin-star {
        width: 19%;
        left: 38px;
        bottom: 42px;
      }

      &.strike {
        width: 19%;
        top: 184px;
        right: 81px;

        @media screen and (max-width: 760px) {
          top: 231px;
        }
      }

    }

    &::before {
      content: "";
      position: absolute;
    }

  }

  &__image {
    &--one {
      bottom: 0px;
      left: -20px;
    }

  }

  &__link {
    background: linear-gradient(45deg, #6c25f8, #935efe);
    color: #fff;
    padding: 12px 24px;
    border-radius: 32px;
    display: inline-flex;
    box-shadow: 1px 2px 6px 0px rgb(108 37 248 / 10%);
    border: 0.4px solid hsl(260deg 63% 61%);
    position: relative;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 8px -2px #0000004d,
      0 1px 1px #0000002e,
      inset 0 1px #fff3,
      inset 0 -1px #0003;

    &::before {
      background-color: rgb(224 210 255);
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 5px;
      left: 6px;
      z-index: -1;
      border-radius: 42px;
    }

    &--alt {
      background: linear-gradient(45deg, #091c30, #1b1144);
      box-shadow: 1px 2px 6px 0px rgb(108 37 248 / 10%);
      border: 0.4px solid #0f1f30;
    }
  }

  &__heading {
    h4 {
      --font-size: 3.6rem;
      font-size: var(--font-size);
      max-width: 360px;
      line-height: calc(var(--font-size) * 1.25);
      margin-bottom: 1.2rem;
      font-weight: 500;
      text-transform: capitalize;

      span {
        background: radial-gradient(133.64% 409.58% at 24.66% 0%, #702bf8 0%, #e69e9e 38%, #f58686 47.92%, #C7C9FF 63.8%, #4714ad 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-feature-settings: "salt"on;
      }
    }

    p {
      --font-size: 1.74rem;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.5);
      margin-bottom: 1.6rem;
      max-width: max(75%, 400px);
    }
  }

  &__grid {
    background-size: 40px 40px;
    background-image: linear-gradient(to right, #e5d19e 1px, transparent 1px), linear-gradient(to bottom, #e5d19e 1px, transparent 1px);
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(29deg, white 60%, #ffffffa1);
    z-index: -1;
    border-radius: 44px;

    &--first {
      background-image: linear-gradient(341deg, rgba(255, 255, 255, 0.7215686275) 1%, white 58%, rgba(255, 255, 255, 0.631372549));
    }

    &--multiple {
      background-image: linear-gradient(1deg, rgba(255, 255, 255, 0.7215686275) 1%, white 86%, rgba(255, 255, 255, 0.631372549));
    }
  }
}

.c-howto {
  padding-block: 6rem 20rem;

  @media screen and (max-width: 760px) {
    padding-block-end: 12rem;
  }

  &__intro {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, auto);
    display: grid;
    grid-auto-flow: row;
    margin-block: 2rem 6.8rem;
    gap: 8px;

    h3 {
      --font-size: 8.4rem;
      // display: flex;
      letter-spacing: -0.02em;
      position: relative;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);
      color: var(--theme-black);
      font-weight: 400;

      @media screen and (max-width: 760px) {
        --font-size: 7.4rem;
      }

      &:first-of-type {
        grid-area: 1 / 2 / 3 / -1;

        @media screen and (max-width: 760px) {
          grid-column-start: 1;
        }
      }

      &:nth-child(2) {
        grid-area: 3/3/3/-1;

        @media screen and (max-width: 760px) {
          grid-column-start: 1;
        }
      }

      &:nth-child(3) {
        grid-area: 5/5/8/-1;

        @media screen and (max-width: 760px) {
          grid-column-start: 1;
        }
      }

      span {
        background: radial-gradient(133.64% 409.58% at 24.66% 0%, #7D82FF 0%, #FFC7C7 29.45%, #FFC7C7 47.92%, #C7C9FF 63.8%, #7D82FF 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

      }
    }

  }

  &__appshot {
    position: relative;

    p {
      --font-size: 3.2rem;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.6);
      margin-bottom: 3.2rem;
      max-width: 540px;
    }
  }

  &__step {
    display: grid;
    gap: 24px;
    position: relative;
    grid-template-columns: 1fr;

    h4 {
      --font-size: 2.8rem;
      font-size: var(--font-size);
      margin-block-end: 0.8rem;
      text-transform: capitalize;
      font-weight: 400;
    }

    p {
      --font-size: 2rem;
      font-weight: 400;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.5);
      opacity: 0.96;
      max-width: 420px;
      margin-bottom: 1.2rem;
    }

    span {
      --font-size: 2.8rem;
      --width: 100%;
      --height: 54px;
      font-size: var(--font-size);
      margin-bottom: 1.2rem;
      font-weight: 500;
      text-transform: capitalize;
      background: transparent;
      width: var(--width);
      height: var(--height);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 3.2px solid rgb(159 159 203 / 32%);
      color: rgb(63 44 103);
      padding-top: 4px;
    }
  }

  &__steps {
    position: relative;
    z-index: 500;
    isolation: isolate;
    padding-block: 3.6rem 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 44px;
    background: rgba(130, 73, 246, 0.0705882353);
    max-height: 760px;
    align-items: flex-start;
    border-radius: 40px;
    width: 100%;
    overflow: hidden;
    padding-inline: 3.2rem 2rem;

    @media screen and (max-width: 760px) {
      min-height: 300px;
      max-height: 480px;
      margin-block-end: 2rem;
      border-radius: 32px;
    }

    &--two,
    &--three {
      justify-content: flex-end;
      align-items: center;
      text-align: center;
    }


    &--two {
      background: #fff8ee;
    }

    &--three {
      background: #ffeeee;
    }
  }



  &__grid {
    display: grid;
    height: 100%;
    width: 100%;
    gap: 20px;
    padding-inline-start: 20px;
  }

  &__illustration {
    position: absolute;
    opacity: .2;
    right: 20px;
    width: 18%;
    top: 38px;
    visibility: hidden;
  }

  &__image {
    &--phone {
      width: 95%;
      margin-left: 36px;
      position: relative;
      z-index: 100;
    }

    &--card {
      position: absolute;
      width: 70%;
      top: -70px;
      left: 14%;
    }

    &--glow {
      position: absolute;
      width: 70%;
      top: -36px;
      left: 15%;
    }

    &--patternone,
    &--patternonealt,
    &--patterntwo,
    &--patternthree {
      position: absolute;
      object-fit: cover;

    }

    &--patternone {
      right: -110px;
      top: -128px;
    }

    &--patternonealt {
      bottom: -228px;
      width: 100%;
    }

    &--patterntwo {
      top: -94px;
    }

    &--patternthree {
      top: -160px;
      width: 90%;
    }
  }

  &__buttons {
    margin: 20px 0 12px;
    display: flex;
    gap: 2rem;
    width: 76%;

    a {
      transition: all 0.2s ease-in-out;
      position: relative;

      img {
        width: 100%;
      }

      &::before {
        background-color: rgb(201, 173, 255);
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 5px;
        z-index: -1;
        border-radius: 42px;
      }
    }
  }
}

.c-testimonials {
  --bg-color: #1b1b1e;
  background: var(--bg-color);
  width: 100%;
  border-radius: 52px 52px 0 0;
  min-height: 100vh;
  margin-block-start: -52px;
  display: block;
  z-index: 100;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  padding-block: 6.4rem 12.8rem;

  @media screen and (max-width: 760px) {
    padding-block: 2.4rem 12.8rem;
    padding-inline: 1.2rem;
    border-radius: 32px 32px 0 0;
  }

  header {
    margin-block: 1.2rem 4.4rem;

    h4 {
      --font-size: 3.6rem;
      --color: #FFFBF2;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);
      margin: 0.8rem 0;
      font-weight: 400;
      color: var(--color);
    }

    p {
      --font-size: 2rem;
      --color: #FFECC2;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);
      margin: 0.8rem 0;
      font-weight: 300;
      color: var(--color);
    }
  }

  &__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-rows: masonry;

    @media screen and (max-width: 760px) {
      grid-template-columns: 1fr;
    }
  }

  &__testimonial {
    --bg-color: #242328;
    background: var(--bg-color);
    padding: 2.4rem 2rem 2.8rem;
    border-radius: 2.4rem;

    a {
      color: #FFFBF2;

      header {
        display: flex;
        gap: 16px;
        margin-bottom: 2rem;

        img {
          border-radius: 10px;
          height: 56px;
        }

        span {
          h5 {
            --font-size: 1.6rem;
            font-size: var(--font-size);
            line-height: calc(var(--font-size) * 1.2);
            margin: 0.8rem 0;
            font-weight: 500;
          }

          p {
            --font-size: 1.2rem;
            font-size: var(--font-size);
            line-height: calc(var(--font-size) * 1.2);
            margin: 0.8rem 0;
          }
        }
      }

      p {
        --font-size: 1.52rem;
        font-size: var(--font-size);
        line-height: calc(var(--font-size) * 1.5);
        font-weight: 300;
        white-space: pre-line;
        word-break: break-word;
        color: hsl(252deg 7% 70%);
      }
    }

    // &.wide {}

    // &.big {}

    // &.tall {}

    .r-14lw9ot {
      background: transparent;
    }
  }
}

.c-faq {
  --bg-color: #FFFBF2;
  --color: var(--theme-black);
  background: var(--bg-color);
  color: var(--color);
  width: 100%;
  border-radius: 52px 52px 0 0;
  min-height: 100vh;
  margin-block-start: -52px;
  display: block;
  z-index: 100;
  position: relative;
  isolation: isolate;
  padding-block: 6.4rem 12.8rem;
  position: relative;
  z-index: 100;

  @media screen and (max-width: 760px) {
    padding-block: 2.4rem 12.8rem;
    padding-inline: 1.2rem;
    border-radius: 32px 32px 0 0;
  }

  &__header {
    padding-block: 2rem 2.4rem;

    h3 {
      --font-size: 5.2rem;
      --color: #FFFBF2;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);
      margin: 0.8rem 0 1.6rem;
      font-weight: 500;
      color: rgba(var(--theme-black-rgb), 1);
      text-transform: capitalize;
      max-width: 500px;

      @media screen and (max-width: 760px) {
        --font-size: 4rem;
      }
    }

    p {
      --font-size: 2rem;
      --color: rgba(var(--theme-black-rgb), 1);
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);
      margin: 0.8rem 0;
      font-weight: 400;
      color: var(--color);
    }
  }

  &__widget {
    details {
      // --bg-color: var(--theme-primary);
      // background: var(--bg-color);
      margin-block: 2rem;

      summary {
        // border-block-end: 0.6px solid #FFE099;
        --font-size: 1.9rem;
        --color: rgba(var(--theme-black-rgb), 1);
        font-size: var(--font-size);
        line-height: calc(var(--font-size) * 1.5);
        margin: 0;
        font-weight: 400;
        color: var(--color);
        padding: 2rem;
        list-style-type: none;
        display: flex;
        gap: 20px;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0px 3px 6px -3px rgb(247, 237, 207),
          0px 2px 4px -2px rgb(247, 237, 207),
          0px 1px 2px -1px rgb(247, 237, 207),
          0px 1px 1px -1px rgb(247, 237, 207),
          0px 1px 0px -1px rgb(247, 237, 207);
        // align-items: center;

        &::-webkit-details-marker {
          display: none;
        }

        &::before {
          content: url(./images/faq-chevron.svg);
          will-change: transform;
          transition: all .3s ease-in-out;
          display: inline-flex;
          align-items: center;
        }
      }

      // &:first-child {
      //   summary {
      //     border-block-start: 0.6px solid #FFE099;
      //   }
      // }

      p,
      ul {
        background: hsl(0deg, 0%, 100%);
        padding: 3.2rem 6rem;
        --font-size: 1.7rem;
        --color: rgba(var(--theme-black-rgb), .7);
        font-size: var(--font-size);
        line-height: calc(var(--font-size) * 1.64);
        border-radius: 0 0 16px 16px;
        color: var(--color);
      }

      ul {
        list-style: none;
      }

      &[open] {
        &>summary {
          --bg-color: var(--white);
          background: var(--bg-color);
          border-block-end: none;
          border-radius: 16px 16px 0 0;


          &::before {
            transform: rotate(180deg);
            transform-origin: 50% 40% 0px;
          }
        }

        ul {
          margin: 0;
        }
      }
    }
  }
}


.c-cta {
  position: relative;
  z-index: 1000;

  &__text {
    --bg-color: var(--theme-black);
    background: var(--bg-color);
    padding: 4rem 2rem;
    width: 100%;
    border-radius: 32px;
    min-height: 400px;
    margin-block-start: -72px;
    margin-block-end: 5rem;
    display: block;
    z-index: 100;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    padding-block: 6.4rem;
    padding-inline: 4.8rem;

    @media screen and (max-width: 760px) {
      padding-inline: 2.4rem;
    }

    h3 {
      --font-size: 3.6rem;
      --color: #FFFBF2;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.3);
      margin: 0.8rem 0 2.8rem;
      max-width: 480px;
      font-weight: 400;
      color: var(--color);
      z-index: 10;
      position: relative;
    }

    a {
      background: linear-gradient(45deg, #6c25f8, #935efe);
      color: #fff;
      padding: 10px 16px;
      border-radius: 10px;
      display: inline-flex;
      box-shadow: 1px 2px 6px 0px rgb(108 37 248 / 10%);
      border: 0.4px solid hsl(260deg, 63%, 61%);
      min-width: 173px;
      justify-content: center;
      z-index: 10;
      position: relative;
      border-radius: 40px;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, .1);
      box-shadow: 0 2px 8px -2px #0000004d, 0 1px 1px #0000002e, inset 0 1px #fff3, inset 0 -1px #0003;
    }
  }

  &__illustration {
    position: absolute;
    z-index: 1;

    &--cards {
      right: -218px;
      width: 67%;
      z-index: 6;

      @media screen and (max-width: 760px) {
        right: -180px;
        width: 99%;
        bottom: -37px
      }
    }

    &--glitz {
      right: 109px;
      top: 10px;
      width: 45%;

      @media screen and (max-width: 760px) {
        top: initial;
        bottom: -30px
      }
    }

    &--coin {
      right: 29px;
      top: 20px;
      z-index: 5;
    }
  }
}


.c-partners {
  opacity: .9;

  &__heading {
    h5 {
      font-weight: 400;
      margin: 20px 0 12px;

      @media screen and (max-width: 760px) {
        margin: 20px 0 8px;
      }

      // @media only screen and (min-width: 1400px) {
      //   width: 18%;
      //   right: 406px;
      //   top: -53px;
      // }
    }
  }

  &__logo {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 32px;
    justify-items: center;
    align-items: center;

    img {
      aspect-ratio: 3/2;
      object-fit: contain;
      // mix-blend-mode: color-burn;
      align-items: center;
    }
  }
}

@keyframes SlideIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 80px, 0);
  }
}

.waitlist-success-toast {
  position: absolute;
}

.Toastify__toast-container--top-right {
  top: 70px !important;
  right: 20px !important;
}


.Toastify__toast-theme--light {
  background: #fff0c8 !important;
  border: 0.3px solid #ffda73;
  box-shadow: none;
}

.Toastify__progress-bar {
  display: none;
}



.Toastify__toast-body>div:last-child {
  color: var(--theme-black);
}

.Toastify__toast-container--top-right {
  top: 86px;
  right: 140px;
}

.Toastify__close-button>svg {
  fill: currentColor;
}

.Toastify__close-button>svg {
  fill: var(--theme-primary);
  height: 10px;
  width: 14px;
}

.Toastify__close-button {
  background: var(--theme-black) !important;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  opacity: 0.7;
}

.c-noise {
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(./images/noise.png);
  background-repeat: repeat;
  background-size: contain;
  background-position: top left;
  z-index: 100;
}
















// .c-bkgrid {
//   display: grid;
//   position: absolute;
//   grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
//   inset: 0px 0 auto 0;
//   grid-auto-flow: row;
//   min-height: 19vh;
//   background: #ffebc8;
//   gap: 2px 2px;
//   z-index: 10;

//   &__item {
//     background: #fffdf9;
//     border-radius: 6px;
//     position: relative;
//     margin: 0 1px 1px 0;
//     z-index: 10;
//     min-height: 50px;
//   }
// }


// .c-glow {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   inset: 0;
//   z-index: 1;

//   &__wrap {
//     width: 100%;
//     position: relative;
//     height: 100%;
//     filter: blur(240px);
//   }

//   &--one {
//     position: absolute;
//     border-radius: 362.53px;
//     transform: rotate(-35.6deg);
//     mix-blend-mode: overlay;
//     right: 261px;
//     background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, rgba(224, 111, 252, .322) 40%, rgba(169, 111, 252, .671) 100%);
//     top: 200px;
//     width: 500px;
//     height: 500px;

//     @-moz-document url-prefix('') {
//       background: radial-gradient(65.67% 65.67% at 41.2% 34.33%,
//           #e06ffc21 40%,
//           #a96ffcab 100%);

//     }

//   }
// }