.c-header {
  display: flex;
  height: 72px;
  min-height: 72px;
  padding: 0 52px;
  width: 100%;
  background: transparent;
  position: absolute;
  inset: 16px 0 0 0;
  z-index: 50;

  @media screen and (max-width: 760px) {
    padding: 12px 4px 0;
    inset: 0;
  }

  .l-wrapper {
    @media only screen and (min-width: 1400px) {
      max-width: 1120px;
    }
  }
}

.c-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  align-items: center;
  width: 100%;
  background: #fff;
  // box-shadow: 0px 4px 5px 0px #33224e0a,
  //   0px -1px 6px 0px #0924410f;
  box-shadow: 0px 4px 5px 0px rgb(255 250 242),
    0px -1px 6px 0px rgb(255 248 233);
  border-radius: 100vmax;
  padding-inline: 6px;

  &__logo {
    isolation: isolate;
    margin-left: 16px;
    margin-top: 7px;

    a {
      img {
        max-width: 88px;
      }
    }

  }

  &__menu {
    ul {
      list-style: none;
      display: flex;
      gap: 0 36px;
      font-size: 15px;
      justify-content: flex-start;
      padding: 0;
      margin: 0;

      li {
        &.hide-mobile {
          @media screen and (max-width: 760px) {
            display: none;
          }

        }
      }
    }
  }

  &__buttons {
    a {
      background: var(--theme-primary);
      border-radius: 8px;
      color: var(--theme-black);
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 1.2rem 2.4rem;
      transition: all .32s ease;

      @media screen and (max-width: 760px) {
        padding: 1.2rem 1.2rem;
      }
    }

  }

  @media screen and (max-width: 760px) {}
}