@use '../utilities/'as *;

.l-wrapper,
.l-wrapper-full,
.l-wrapper-xxl,
.l-wrapper-xl,
.l-wrapper-lg,
.l-wrapper-md,
.l-wrapper-sm {
  --l-gutter-x: 1.5rem;
  --l-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--l-gutter-x) * 0.5);
  padding-left: calc(var(--l-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .l-wrapper-sm,
  .l-wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .l-wrapper-md,
  .l-wrapper-sm,
  .l-wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .l-wrapper-lg,
  .l-wrapper-md,
  .l-wrapper-sm,
  .l-wrapper {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .l-wrapper-xl,
  .l-wrapper-lg,
  .l-wrapper-md,
  .l-wrapper-sm,
  .l-wrapper {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .l-wrapper-xxl,
  .l-wrapper-xl,
  .l-wrapper-lg,
  .l-wrapper-md,
  .l-wrapper-sm,
  .l-wrapper {
    max-width: 1160px;
  }
}

.l-row {
  --l-gutter-x: 1.5rem;
  --l-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--l-gutter-y));
  margin-right: calc(-0.5 * var(--l-gutter-x));
  margin-left: calc(-0.5 * var(--l-gutter-x));
}

.l-row>* {
  --l-gutter-x: 1.5rem;
  --l-gutter-y: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  // width: 100%;
  max-width: 100%;
  padding-right: calc(var(--l-gutter-x) * 0.5);
  padding-left: calc(var(--l-gutter-x) * 0.5);
  margin: 0 auto;
}

$columns: 12;

@for $i from 1 through $columns {
  .l-col-#{$i} {
    flex: 0 0 calc(100% / $columns * $i);
  }

  .l-col-offset-#{$i} {
    margin-left: calc(100% / $columns * $i);
  }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (max-width: 768px) {
  .l-col {
    flex: 0 0 100% !important;
  }

  .d-sm-none {
    display: none;
  }
}

@media (min-width: 992px) {}
@media (min-width: 1200px) {}
@media (min-width: 1400px) {}

.l-flex {
  @include l-centered;

  &-column {
    @include l-centered('column');
  }
}