.c-footer {
  display: flex;
  min-height: 72px;
  width: 100%;
  background: #fffdf9;
  padding-block: 1.2rem 2rem;

  &__top {
    padding-block: 3.2rem;
  }

  &__bottom {
    border-block-start: 0.8px solid #e9c686;
    padding-block: 3.2rem 2rem;
  }

  &__socials {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 760px) {
      justify-content: flex-start;
      padding: 20px 30px 8px;
    }

    img {
      width: 32px;
    }
  }

  &__links {
    // padding: 16px 0 60px;
    gap: 8px;
    // text-align: center;

    @media screen and (max-width: 760px) {
      padding: 20px 30px 8px;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      padding: 20px 30px 32px;
    }

    img {
      margin-block-end: 1.2rem;
    }

    ul {
      display: flex;
      list-style: none;
      padding-block: 1.2rem 2rem;
      padding-inline: 0;
      margin-bottom: 0;
      gap: 16px;

      li {
        a {
          font-weight: 500;
          white-space: nowrap;

          @media screen and (max-width: 760px) {
            font-size: 1.5rem;
          }
        }
      }
    }

  }

}