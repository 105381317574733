:root {
  --font-heading: "LabilGrotesk",
    Arial,
    sans-serif;
  --font-text: "Rotunda",
    Arial,
    sans-serif;
  --white: #fff;
  --black: #000;
  --hue: 260;
  --bkg: var(--hue) 30% 98%;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  // --theme-black: #091c30;
  --theme-black: #1b1b1e;
  --theme-black-rgb: 27, 27, 30;
  --theme-primary: #6d23f9;
  --theme-primary-hsl: 44, 96%, 56%;
  --theme-accent: #f3ecff;
  --theme-accent-rgb: 2555, 226, 84;
}