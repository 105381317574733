@use '../utilities/'as *;

html {
  font-size: 62.5%;

  @include screen(small) {
    font-size: 58%;
  }
}


body {
  @include sexyfont;
  font-family: var(--font-text);
  color: rgba(var(--theme-black-rgb), 1);
  height: 100%;
  position: relative;
  font-size: 1.6rem;
  background: #fffdf9;
  // font-size: toRem(16);
  // background: #fff;
  // background-image: linear-gradient(125deg, rgba(39, 126, 240, 0.15), #f9fcff 52%, rgba(255, 215, 155, 0.15));
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  font-feature-settings: "salt"on;
}

a {
  color: rgba(var(--theme-black-rgb), 1);
  font-family: var(--font-primary);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 52px;
    padding: 14px 35px;
    border-radius: 26px;
    border: 0;
    font-family: var(--font-heading);
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 150ms linear,
      color 150ms linear;

  }
}

menu {
  padding: 0;
}

// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// ::-webkit-scrollbar-track {
//   background: hsl(24 100% 98% / 1);
//   border-radius: 100vw;
// }

// ::-webkit-scrollbar-thumb {
//   background: hsl(24 100% 98% / 1);
//   background-image: linear-gradient(50deg, #b38cfb, #6d23f9 49%, #955cfe 53%, #813ffb 55%, #c8aaff 77%, #cbaeff);
//   border-radius: 100vw;
//   max-height: 30px;
//   border-top: 60px
// }

// @support(scrollbar-color) {
//   scrollbar-color: linear-gradient(50deg, #b38cfb, #6d23f9 49%, #955cfe 53%, #813ffb 55%, #c8aaff 77%, #cbaeff);

// }